import React from "react"
import { useTheme } from "@emotion/react"
import { Button as RebassButton, Box } from "theme-ui"

import TennisNet from "../../../assets/icons/tennis-net-2.svg"
import styled from "@emotion/styled"
import Spinner from "../spinner"

const DisabledContainer = styled(Box)`
  height: 100%;
  width: 100%;
  > div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${(props) => props.theme.colors.darkGreen};
    height: 100%;
    width: 100%;
  }
`

const Button = ({
  loading,
  children,
  variant = "default",
  type,
  onClick,
  disabled,
  showNet = false,
  sx,
  ...props
}) => {
  const theme = useTheme()

  if (variant) {
    const variantStyle = theme[`buttons`][`${variant}`]
    sx = {
      ...variantStyle,
      ...sx,
    }
  }

  return (
    <RebassButton
      sx={{
        fontWeight: "bold",
        cursor: disabled ? "default" : "pointer",
        position: disabled ? "relative" : "initial",
        "&:focus": {
          outline: "none",
        },
        alignItems: "center",
        padding: 0,
        ...sx,
      }}
      type={type || "button"}
      variant={`buttons.${variant}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <div>
        {disabled && showNet ? (
          <DisabledContainer>
            <TennisNet />
            <Box>{children}</Box>
          </DisabledContainer>
        ) : loading ? (
          <Spinner sx={{ width: "20px", margin: "auto" }} />
        ) : (
          children
        )}
      </div>
    </RebassButton>
  )
}

export default Button
