// Add 24 hours from now
export const expire24hours = () => {
  const now = new Date()
  const time = parseInt(now.getTime()) + 24 * 60 * 60 * 1000
  return time
}

// Add 1 hour from now
export const expire1hour = () => {
  const now = new Date()
  const time = parseInt(now.getTime()) + 1 * 60 * 60 * 1000
  return time
}

// Add 2 months from now
export const expire60days = () => {
  const now = new Date()
  const time = parseInt(now.getTime()) + 24 * 60 * 60 * 1000 * 60
  return time
}

/**
 * Time is in int format
 * @param time
 */
export const isExpired = (time) => {
  const now = new Date()
  return parseInt(now.getTime()) > time
}
