import React from "react"
import { FilterProvider } from "./src/context/filter-context"
import { InterfaceProvider } from "./src/context/interface-context"
import NotificationProvider from "./src/context/notification-context"
import { StoreProvider } from "./src/context/store-context"
import "./src/main.css"
import "./src/services/analytics"
import { ThemeProvider } from "./src/theme"

export { wrapPageElement } from "./gatsby-ssr"

export const wrapRootElement = ({ element }) => {
  return (
    <InterfaceProvider>
      <StoreProvider>
        <NotificationProvider>
          <ThemeProvider>
            <FilterProvider>
              {element}
            </FilterProvider>
          </ThemeProvider>
        </NotificationProvider>
      </StoreProvider>
    </InterfaceProvider>
  )
}
