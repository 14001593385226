import { useCallback, useContext, useEffect, useState } from "react"
import StoreContext from "../context/store-context"
import { createClient } from "../util/client"

const client = createClient()

const useProductVariants = ({ productId, contentfulProduct }) => {
  const { cart } = useContext(StoreContext)

  const [variants, allVariants] = useState([])

  const getInventory = useCallback(
    async (productId) => {
      return client.products
        .list({ id: productId, currency_code: cart?.region?.currency_code?.toLowerCase() })
        .then((data) => {
          const variants = data.products[0]?.variants.reduce((acc, next) => {
            acc[next.id] = {
              inventory_quantity: next.inventory_quantity,
              calculated_price: next.calculated_price,
              original_price: next.original_price,
            }

            return acc
          }, {})

          return variants
        })
    },
    [cart.currencyCode]
  )

  const createVariants = useCallback(async () => {
    const inventory = await getInventory(productId)
    const result = contentfulProduct.variants.reduce((acc, next) => {
      const result = {
        id: next.id,
        objectId: next.objectId,
        display: next.display,
        options: next.options,
        title: next.title,
        prices: next.prices,
        inventory_quantity: inventory[next.objectId].inventory_quantity,
        calculated_price: inventory[next.objectId].calculated_price,
        original_price: inventory[next.objectId].original_price,
      }

      acc.push(result)
      return acc
    }, [])

    return result
  }, [getInventory, productId, contentfulProduct.variants])

  useEffect(() => {
    const buildVariants = async () => {
      const result = await createVariants()
      allVariants(result)
    }

    buildVariants()
  }, [createVariants])

  return { variants }
}

export default useProductVariants
