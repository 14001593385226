import React from "react"
import { Text as RebassText } from "theme-ui"
import styled from "@emotion/styled"

const TextStyled = styled(RebassText)`
  padding-top: ${(props) => props.sx?.paddingTop ?? "4px"};
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  color: inherit;
`

const Text = ({ children, ...rest }) => {
  return <TextStyled {...rest}>{children}</TextStyled>
}

export default Text
