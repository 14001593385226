import React from "react"
import { Box } from "theme-ui"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

export const ImageContainer = styled(Box)`
  height: ${({ vh }) => (vh ? `${vh}vh` : ``)};
  width: 100%;
  ${(props) =>
    props.borderRadius &&
    `
    border-radius: ${props.borderRadius}%;

    // make sure to target inner images
    img {
      border-radius: ${props.borderRadius}%;
    }
  `}

  cursor: pointer;

  .primary {
    display: block;
  }

  .secondary {
    display: none;
  }

  a:hover & {
    cursor: pointer;

    .primary {
      display: ${(props) => (props.secondary ? "none" : "block")};
    }

    .secondary {
      display: ${(props) => (props.secondary ? "block" : "none")};
    }
  }
`

const imageStyles = {
  objectFit: "cover",
  height: "100%",
  width: "100%",
  maxWidth: "none",
}

const Image = ({ primary, secondary, onClick, alt, id, ratio, ...rest }) => {
  return (
    <ImageContainer onClick={onClick} secondary={secondary} key={id} {...rest}>
      {primary && (
        <GatsbyImage
          className="primary"
          image={primary.gatsbyImageData || primary}
          imgStyle={imageStyles}
          alt={alt || " "}
          size={{ aspectRatio: ratio }}
        />
      )}
      {secondary && (
        <GatsbyImage
          className="secondary"
          imgStyle={imageStyles}
          image={secondary.gatsbyImageData || secondary}
          alt={alt || " "}
          size={{ aspectRatio: ratio }}
        />
      )}
    </ImageContainer>
  )
}

export default Image
