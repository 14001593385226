import styled from "@emotion/styled"
import { useLocation } from "@reach/router"
import React, { useEffect, useState, useContext } from "react"
import { Flex, Image } from "theme-ui"
import BackgroundNet from "../../assets/images/background-net.png"
import CursorClose from "../../assets/images/cursor-close-128.svg"
import { useInterfaceContext } from "../../context/interface-context"
import { expire60days } from "../../util/time"
import Text from "../base/text/text"
import CartDrawer from "../cart-drawer"
import FilterDrawer from "../filter-drawer"
import SearchDrawer from "../drawers/search-drawer"
import Modal from "../drawers/modal-drawer"
import ProductNav from "../drawers/product-nav"
import RegionDrawer from "../drawers/region-drawer"
import Footer from "../footer"
import Header from "../header"
import NotificationsContainer from "../notifications"
import StoreContext from "../../context/store-context"
import "./layout.css"

const Main = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(props) =>
    props.removeExtraSpacing
      ? props.theme.marginTop.header
      : props.theme.marginTop.headerWithBar}px;

  ${(props) => props.theme.bp.mobile} {
    > div:first-of-type {
      margin-top: 0px;
    }
  }
`

const Container = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.darkGreen};

  ${(props) =>
    (props.isCartOpen || props.isFilterOpen || props.isProductNavOpen) &&
    `
  overflow: hidden;
  max-height: 100vh;
  `}
`

const CartDrawerBackground = styled(Flex)`
  width: 100%;
  position: absolute;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 1020;
  cursor: url(${CursorClose}) 64 64, auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Layout = ({ hideFooter, removeExtraSpacing, children }) => {
  const { isCartOpen, isFilterOpen, isProductNavOpen } = useInterfaceContext()
  const { pathname } = useLocation()
  const [showBanner, setShowBanner] = useState(false)
  const { cart } = useContext(StoreContext)

  const onClose = () => {
    setShowBanner(false)
    localStorage.setItem("bannerClosed", "true")
  }

  useEffect(() => {
    try {
      const bannerShownBefore = JSON.parse(localStorage.getItem("bannerClosed"))
      if (!bannerShownBefore) {
        setShowBanner(true)
      }
    } catch (err) {
      console.error("localstorage setItem failed", err)
    }
  }, [])

  const isCheckout = pathname.includes("checkout")

  return (
    <Container
      isCartOpen={isCartOpen}
      isFilterOpen={isFilterOpen}
      isProductNavOpen={isProductNavOpen}
    >
      {(isCartOpen || isFilterOpen || isProductNavOpen) && (
        <CartDrawerBackground>
          <Image src={BackgroundNet} />
        </CartDrawerBackground>
      )}
      <Header />
      <ProductNav />
      <RegionDrawer />
      <SearchDrawer />
      {!isCheckout && <CartDrawer />}
      {!isCheckout && <FilterDrawer />}
      <NotificationsContainer />
      <Modal />
      <Main removeExtraSpacing={removeExtraSpacing}>{children}</Main>
      {!hideFooter && <Footer />}
      {!isCheckout && cart?.countryCode === "gb" && showBanner && <NoticeBanner onClose={onClose} />}
    </Container>
  )
}

const NoticeBanner = ({ onClose }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        maxWidth: ["100%", "300px"],
        border: "darkGreen",
        position: "absolute",
        right: "0px",
        top: ["auto", "69px"],
        bottom: ["0px", "auto"],
        zIndex: 1001,
        backgroundColor: "background",
        fontSize: "12px",
        padding: "1em",
      }}
    >
      <Flex>
        <Text pt={2} sx={{ flex: 1 }}>
          To ensure a smooth and easy shopping experience for our UK customers, we’re happy to announce that Palmes is now VAT registered in the UK.<br /><br />
          In short, this means that no additional costs will incur to UK shoppers on Palmes.co so that you can apply the care-free attitude that you have on the tennis court to shopping at Palmes.co.<br /><br />
          No fees; just feelings of tennis.
        </Text>
        <Text onClick={onClose} sx={{ cursor: "pointer" }}>
          x
        </Text>
      </Flex>
    </Flex>
  )
}

export default Layout
