import React, { forwardRef } from "react"
import { Input as RebassInput } from "@rebass/forms"
import styled from "@emotion/styled"

const StyledInput = styled(RebassInput)`
  border: ${(props) => (props.borderNone ? "none" : props.theme.borders.thin)};

  &:focus {
    outline: ${(props) =>
      props.outline ? props.theme.colors.darkGreen : "none"};
    border-color: ${(props) => props.theme.colors.darkGreen};
    outline-color: ${(props) => props.theme.colors.darkGreen};
  }
`

const Input = forwardRef(function createStyledInput(props, ref) {
  return (
    <StyledInput
      ref={ref}
      borderNone={props.borderNone}
      outline={props.outline}
      {...props}
    >
      {props.children}
    </StyledInput>
  )
})

export default Input
