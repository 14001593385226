const noDivisionCurrencies = ["krw"]

export function salePercentage(prodVariant) {
  if (prodVariant.original_price > prodVariant.calculated_price) {
    const sale = prodVariant.calculated_price
    const regular = prodVariant.original_price
    const percentage = 100 - Math.round((sale / regular) * 100)
    return `${percentage}%`
  }

  return null
}

export function formatCartItemPrice(cart, cartItem) {
  const variantPrice = getVariantPrice(cart, cartItem.variant)
  const currencyCode = cart.currencyCode || cart.currency_code

  return formatMoneyAmount({
    currencyCode,
    amount: variantPrice * cartItem.quantity,
  })
}

function getTaxRate(cart) {
  if ("taxRate" in cart) {
    return cart.taxRate / 100
  } else if ("tax_rate" in cart) {
    return cart.tax_rate / 100
  } else if (cart.region) {
    return cart.region && cart.region.tax_rate / 100
  }
  return 0.25
}

export function normalizeAmount(currency, amount) {
  let divisor = 100
  if (noDivisionCurrencies.includes(currency.toLowerCase())) {
    divisor = 1
  }

  return Math.floor(amount) / divisor
}

export function getVariantPrice(cart, variant, withSale = false) {
  let taxRate = getTaxRate(cart)

  if (withSale && variant.calculated_price) {
    return variant.calculated_price * (1 + taxRate)
  }

  if (variant?.original_price) {
    return variant.original_price * (1 + taxRate)
  }

  if (variant?.prices) {
    const currencyCode = cart.currencyCode || cart.currency_code

    let moneyAmount = variant.prices.find(
      (p) => p.currency_code.toLowerCase() === currencyCode.toLowerCase()
    )

    if (!moneyAmount?.amount) {
      return undefined
    }

    return moneyAmount.amount * (1 + taxRate)
  }

  return undefined
}

export function formatMoneyAmount(moneyAmount, digits, taxRate = 0) {
  let locale = "en-US"

  let price = (moneyAmount.amount * (1 + taxRate / 100)) / 100

  if (moneyAmount.currencyCode.toLowerCase() === "dkk") {
    // By wish from Nikolaj, we format dkk like `kr800.00`
    return `Kr. ${price}`
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: moneyAmount.currencyCode,
    minimumFractionDigits: 0,
  }).format(price)
}

export function formatPrices(cart, prices, digits = 2) {
  let amount = prices
  if (typeof prices === "object") {
    amount = prices.find(
      (p) => p.currencyCode.toLowerCase() === currencyCode.toLowerCase()
    )
  }
  const taxRate = getTaxRate(cart)
  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount(
    {
      currencyCode,
      amount: amount * (1 + taxRate),
    },
    digits
  )
}

export function formatVariantPrice(
  cart,
  variant,
  withSale = false,
  digits = 2
) {
  const currencyCode = cart.currency || cart.currencyCode
  return formatMoneyAmount(
    {
      currencyCode,
      amount: getVariantPrice(cart, variant, withSale),
    },
    digits
  )
}

export function formatCartShippingTotal(cart, includeTax = true) {
  let shippingTotal =
    "shipping_total" in cart ? cart.shipping_total : cart.shippingTotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    shippingTotal += shippingTotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: shippingTotal,
  })
}

export function formatCartSubtotal(cart, includeTax = true) {
  let subtotal = cart.subtotal
  if (includeTax) {
    const taxRate = getTaxRate(cart)
    subtotal += subtotal * taxRate
  }

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal,
  })
}

export function formatVatDeduction(cart) {
  let subtotal = cart.subtotal || cart.subTotal

  const currencyCode = cart.currencyCode || cart.currency_code
  return formatMoneyAmount({
    currencyCode,
    amount: subtotal * 0.25,
  })
}

export function formatCartTotal(cart, allowNegative = true) {
  const currencyCode = cart.currencyCode || cart.currency_code
  let amount = cart.total
  if (!allowNegative && cart.total < 0) {
    amount *= -1
  }
  return formatMoneyAmount({
    currencyCode,
    amount,
  })
}

export function formatCartGiftCard(cart) {
  const giftCardTotal =
    "gift_card_total" in cart ? cart.gift_card_total : cart.giftCardTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: giftCardTotal * (1 + taxRate),
  })
}

export function formatCartDiscount(cart) {
  const discountTotal =
    "discount_total" in cart ? cart.discount_total : cart.discountTotal
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)
  return formatMoneyAmount({
    currencyCode,
    amount: discountTotal * (1 + taxRate),
  })
}

export function formatShipping(amount, cart) {
  const currencyCode = cart.currencyCode || cart.currency_code
  const taxRate = getTaxRate(cart)

  return formatMoneyAmount({
    currencyCode,
    amount: amount * (1 + taxRate),
  })
}

export function formatOrderDiscount(order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: order.discount_total * (1 + taxRate),
  })
}

function getOrderTaxRate(order) {
  if ("tax_rate" in order) {
    return order.tax_rate / 100
  } else if (order.region) {
    return order.region && order.region.tax_rate / 100
  }
  return 0.25
}

export function formatOrderShipping(price, order) {
  const currencyCode = order.currencyCode || order.currency_code
  const taxRate = getOrderTaxRate(order)
  return formatMoneyAmount({
    currencyCode,
    amount: (price += price * taxRate),
  })
}
