import {
  formatCartTotal,
  formatMoneyAmount,
  formatPrices,
  normalizeAmount,
} from "../util/prices"

const BRANCH = process.env.BRANCH || "master"

if (typeof window !== "undefined") {
  window.analytics.addSourceMiddleware(({ payload, integrations, next }) => {
    const ints = payload.integrations()

    delete ints.All

    next(payload)
  })
}

if (
  typeof window !== "undefined" &&
  typeof window.performance !== "undefined"
) {
  window.onload = () => {
    setTimeout(() => {
      let found = false
      if (performance.getEntriesByType) {
        const entries = performance.getEntriesByType("navigation")
        for (const e of entries) {
          found = true

          const pageLoadTime = e.loadEventEnd - e.redirectStart
          // Request + Request Time
          const totalTime = e.responseEnd - e.requestStart
          // Response time with cache seek
          const fetchTime = e.responseEnd - e.fetchStart

          // Response time with Service worker
          let workerTime = 0
          if (e.workerStart > 0) {
            workerTime = e.responseEnd - e.workerStart
          }

          // Time To First Byte
          const ttfb = e.responseStart - e.requestStart

          // Redirect Time
          const redirectTime = e.redirectEnd - e.redirectStart

          if (window && window.analytics) {
            if (window.analytics.track) {
              window.analytics.track("Site Loaded", {
                nonInteraction: 1,
                page_load_time: pageLoadTime,
                request_time: totalTime,
                fetch_time: fetchTime,
                redirect_time: redirectTime,
                transfer_size: e.transferSize,
                decoded_body_size: e.decodedBodySize,
                ttfb,
              })
            }
          }
        }
      }

      if (!found && performance.timing) {
        const e = performance.timing
        const pageLoadTime = e.loadEventEnd - e.navigationStart
        // Request + Request Time
        const totalTime = e.responseEnd - e.requestStart
        // Response time with cache seek
        const fetchTime = e.responseEnd - e.fetchStart

        // Time To First Byte
        const ttfb = e.responseStart - e.requestStart

        // Redirect Time
        const redirectTime = e.redirectEnd - e.redirectStart

        if (window && window.analytics) {
          if (window.analytics.track) {
            window.analytics.track("Site Loaded", {
              nonInteraction: 1,
              page_load_time: pageLoadTime,
              request_time: totalTime,
              fetch_time: fetchTime,
              redirect_time: redirectTime,
              ttfb,
            })
          }
        }
      }
    }, 0)
  }
}

function compileGAProducts(items) {
  return items.map((i) => ({
    id: i.content.variant.sku,
    name: i.content.variant.sku,
    sku: i.content.variant.sku,
    price: i.content.variant.prices.find(
      (ma) => ma.currency_code.toLowerCase() === "eur"
    ).amount,
    quantity: i.quantity,
  }))
}

export function trackCartViewed(cart) {
  if (window && window.analytics) {
    window.analytics.track("Cart Viewed", {
      cart_id: cart.id,
      products: cart.items.map((i) => {
        const skuParts = i.variant.sku?.split("-") || []
        skuParts.pop()
        const currency = cart.currencyCode
        const price = normalizeAmount(currency, i.unit_price)
        return {
          product_id: i.variant.product_id,
          sku: skuParts.join("-"),
          name: i.title,
          brand: "Palmes",
          variant: i.variant.sku || "",
          price,
          currencyCode: currency,
        }
      }),
    })
  }
}

export function trackSplitTestView(label) {
  if (window && window.analytics) {
    window.analytics.track("Split Test Viewed", {
      label,
    })
  }
}

export function trackCheckoutInitialized(cart) {
  try {
    if (window && window.analytics) {
      window.analytics.track("Checkout Started", {
        order_id: cart.id,
        value: normalizeAmount(cart.currencyCode, cart.total),
        revenue: normalizeAmount(cart.currencyCode, cart.subtotal),
        discount: normalizeAmount(cart.currencyCode, cart.discountTotal),
        tax:
          normalizeAmount(cart.currencyCode, cart.subtotal) *
          (cart.taxRate / 100),
        coupon: cart.couponCode,
        currency: cart.currencyCode,
        display_total: formatCartTotal(cart),
        products: cart.items.map((item) => {
          const skuParts = item.variant.sku?.split("-") || []
          skuParts.pop()

          const price = normalizeAmount(cart.currencyCode, item.unit_price)

          let imgUrl = item.thumbnail || ""
          if (imgUrl.startsWith("//")) {
            imgUrl = `https:${imgUrl}`
          }

          return {
            product_id: item.variant.product_id,
            sku: skuParts.join("-"),
            name: item.title,
            variant: item.variant.sku,
            display_price: formatMoneyAmount(
              {
                currencyCode: cart.currencyCode,
                amount: item.unit_price,
              },
              0
            ),
            color: item.description,
            price,
            quantity: item.quantity,
            image_url: imgUrl,
          }
        }),
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export function trackCheckoutStepViewed(cart, step) {
  if (window && window.analytics && cart) {
    window.analytics.track(
      "Checkout Step Viewed",
      {
        checkout_id: cart.id,
        step,
        shipping_method: cart.shippingMethod && cart.shippingMethod.name,
        payment_method: cart.paymentMethod && cart.paymentMethod.pm_identifier,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackCheckoutStepCompleted(cart, step) {
  if (window && window.analytics && cart) {
    window.analytics.track(
      "Checkout Step Completed",
      {
        checkout_id: cart.id,
        step,
        shipping_method: cart.shippingMehtod && cart.shippingMethod.name,
        payment_method: cart.paymentMethod && cart.paymentMethod.pm_identifier,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackPaymentEntered(cart, step) {
  if (window && window.analytics) {
    window.analytics.track(
      "Payment Info Entered",
      {
        checkout_id: cart.id,
        order_id: cart.id,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackProductListViewed(collectionName, products) {
  if (window && window.analytics) {
    window.analytics.track("Product List Viewed", {
      list_id: collectionName,
      category: collectionName,
      products: products.map(
        (p, index) => {
          if ("duvetProduct" in p) {
            p = p.duvetProduct
          }
          return {
            product_id: p.objectId,
            sku: p.sku,
            name: p.title,
            category: collectionName,
            position: index + 1,
          }
        },
        {
          // integrations: handleIntegrations(),
        }
      ),
    })
  }
}

export function trackProductViewed(product) {
  if (window && window.analytics) {
    window.analytics.track("Product Viewed", {
      product_id: product.objectId,
      handle: product.handle,
      sku: product.sku,
      name: product.title,
      brand: "Palmes",
      url: window.location.href,
      image_url: product.thumbnail && product.thumbnail.file.url,
    })
  }
}

export function trackSizeSelected(product, variant, collection) {
  if (window && window.analytics) {
    window.analytics.track(
      "Product Clicked",
      {
        product_id: product.objectId,
        sku: product.sku,
        name: product.title,
        variant: variant.sku,
        category: collection.name,
      },
      {
        //integrations: handleIntegrations(),
      }
    )
  }
}

export function trackProductClicked(product, collection) {
  if (window && window.analytics) {
    window.analytics.track(
      "Product Clicked",
      {
        product_id: product.objectId,
        sku: product.sku,
        name: product.title,
        category: collection.name,
        list: collection.name,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackAddToCart(cart, product, variant, quantity, unit_price) {
  const currencyCode = cart.currencyCode

  const ma =
    unit_price ||
    variant?.prices?.find(
      (ma) => ma.currency_code.toLowerCase() === currencyCode.toLowerCase()
    )

  const price = normalizeAmount(currencyCode, ma?.amount)

  if (window && window.analytics) {
    window.analytics.track(
      "Product Added",
      {
        cart_id: cart.id,
        product_id: product.objectId,
        sku: product.sku,
        name: product.title,
        brand: "Palmes",
        variant_id: variant.id,
        variant_title: variant.title,
        price,
        currency: currencyCode,
        quantity,
        url: window?.location?.href,
        image_url: product.thumbnail,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackCouponEntered(cart, couponCode) {
  if (window && window.analytics) {
    window.analytics.track(
      "Coupon Entered",
      {
        order_id: cart.id,
        cart_id: cart.id,
        coupon_id: couponCode,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackCouponApplied(cart, couponCode) {
  if (window && window.analytics) {
    window.analytics.track(
      "Coupon Applied",
      {
        order_id: cart.id,
        cart_id: cart.id,
        discount: cart.discount,
        coupon_id: couponCode,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackCouponRemoved(cart) {
  if (window && window.analytics) {
    window.analytics.track(
      "Coupon Removed",
      {
        order_id: cart.id,
        cart_id: cart.id,
        discount: cart.discount,
        coupon_id: cart.couponCode,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackRemoveFromCart(cart, product, variant, quantity, price) {
  const currencyCode = cart.currencyCode

  if (window && window.analytics) {
    window.analytics.track(
      "Product Removed",
      {
        cart_id: cart.id,
        product_id: product.id,
        sku: product.sku,
        name: product.title,
        brand: "Palmes",
        variant_id: variant.id,
        variant_sku: variant.sku,
        variant_title: variant.title,
        price: price,
        currency: currencyCode,
        quantity,
        image_url: product.thumbnail,
      },
      {
        // integrations: handleIntegrations(),
      }
    )
  }
}

export function trackPurchase(order) {
  const currency = order.currency_code
  let contents = order.items.map((i) => {
    // Return facebook content object
    return {
      id: i.variant.product_id,
      variant: i.variant.sku,
      quantity: i.quantity,
      item_price: normalizeAmount(currency, i.unit_price),
    }
  })

  if (window && window.analytics) {
    let coupon
    if (order.discounts && order.discounts.length) {
      coupon = order.discounts[0].code
    }

    window.analytics.track("Purchase", {
      checkout_id: order.cart_id,
      order_id: order.id,
      email: order.email,
      region_id: order.region_id,
      subtotal: normalizeAmount(currency, order.subtotal),
      revenue: normalizeAmount(currency, order.total - order.tax_total),
      shipping: normalizeAmount(currency, order.shipping_total),
      tax: normalizeAmount(currency, order.tax_total),
      discount: normalizeAmount(currency, order.discount_total),
      coupon,
      value: normalizeAmount(currency, order.total),
      transaction_id: order.display_id,
      currency: order.currency_code.toUpperCase(),
      products: order.items.map(
        (i) => {
          const currency = order.currency_code.toUpperCase()
          const price = normalizeAmount(currency, i.unit_price)
          const skuParts = i.variant.sku?.split("-") || []
          skuParts.pop()

          return {
            product_id: i.variant.product_id,
            sku: skuParts.join("-"),
            name: i.title,
            brand: "Palmes",
            variant: i.variant.sku || "",
            price,
            currencyCode: currency,
          }
        },
        {
          // integrations: handleIntegrations(),
        }
      ),
    })
  }

  //if (typeof window !== "undefined") {
  //  if (window.fbq) {
  //    // Register FB Purchase
  //    window.fbq("track", "Purchase", {
  //      currency: currency,
  //      content_type: "product",
  //      value: normalizeAmount(currency, order.subtotal),
  //      contents,
  //    })
  //  }
  //}
}

export function trackRestockSignup(cart, item) {
  const reformattedPrices = item.prices.map((price) => {
    return {
      currencyCode: price.currency_code || price.currencyCode,
      amount: price.amount,
    }
  })

  if (window && window.analytics) {
    const skuParts = item.sku?.split("-") || []
    skuParts.pop()
    window.analytics.track("Restock Notification Signed Up", {
      sku: skuParts.join("-"),
      name: item.title,
      variant: item.sku,
      variant_id: item.id,
      objectId: item.objectId,
      display_price: formatPrices(cart, reformattedPrices, 0),
      options: item.options,
      prices: item.prices,
      quantity: item.quantity,
      image_url: item.imgUrl,
    })
  }
}
