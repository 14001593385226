import styled from "@emotion/styled"
import React, { useCallback, useEffect } from "react"
import { Flex } from "theme-ui"
import { useNotificationContext } from "../../context/notification-context"
import Text from "../base/text/text"

const Container = styled(Flex)`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1015;
  margin-top: ${(props) => props.theme.marginTop.header - 1}px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`

const NotificationsContainer = () => {
  const { notificationList, setNotificationList } = useNotificationContext()

  const handleRemove = useCallback(() => {
    if (!Array.isArray(notificationList)) return
    if (notificationList.length === 0) {
      clearTimeout(timeout)
      return
    }

    const timeout = setTimeout(() => {
      setNotificationList((list) => {
        const result = [...list]
        result.splice(0, 1)
        return result
      })
    }, notificationList[0].time || 7000)
  }, [notificationList, setNotificationList])

  const handleRemoveIndex = (index) => {
    if (index >= 0) {
      const list = [...notificationList]
      list.splice(index, 1)
      setNotificationList(list)
    }
  }

  useEffect(() => {
    handleRemove()
  }, [handleRemove])

  return (
    <Container>
      {notificationList?.map((notification, index) => (
        <Notification
          key={`notification-${index}`}
          id={notification.id}
          type={notification.type}
          message={notification.message}
          time={notification.time}
          renderContent={notification.renderContent}
          sx={notification.sx}
          handleRemove={() => handleRemoveIndex(index)}
          onClick={notification.onClick}
        />
      ))}
    </Container>
  )
}

const StyledNotification = styled(Flex)`
  font-size: 12px;
  max-width: ${(props) => (props.sx?.maxWidth ? props.sx.maxWidth : "100%")};
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1em;
  margin-bottom: 1em;
  background: ${(props) => props.theme.colors.background};
  border-top: ${(props) => props.theme.borders.thin};
  border-bottom: ${(props) => props.theme.borders.thin};
`

export const Notification = ({
  id,
  type,
  message,
  handleRemove,
  renderContent,
  sx,
  onClick,
}) => {
  return (
    <StyledNotification id={id} type={type} sx={sx}>
      {renderContent ? (
        renderContent()
      ) : (
        <>
          <Text
            sx={{ display: "flex", flexWrap: "wrap", paddingRight: "8px" }}
            onClick={onClick}
          >
            {message}
          </Text>
          <Flex sx={{ cursor: "pointer" }} onClick={handleRemove}>
            <Text>x</Text>
          </Flex>
        </>
      )}
    </StyledNotification>
  )
}

export const NotificationType = {
  success: "SUCCESS",
  warning: "WARNING",
  info: "INFO",
}

export default NotificationsContainer
