import React, { useContext, useState } from "react"

const defaultContext = {
  notificationList: [],
  setNotificationList: () => {},
}

const Context = React.createContext(defaultContext)

const NotificationProvider = ({ children }) => {
  const [notificationList, setNotificationList] = useState([])

  return (
    <Context.Provider
      value={{
        notificationList,
        setNotificationList,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default NotificationProvider

export const useNotificationContext = () => useContext(Context)
