// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-done-js": () => import("./../../../src/pages/done.js" /* webpackChunkName: "component---src-pages-done-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-newsletter-page-js": () => import("./../../../src/templates/newsletter-page.js" /* webpackChunkName: "component---src-templates-newsletter-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-story-page-js": () => import("./../../../src/templates/story-page.js" /* webpackChunkName: "component---src-templates-story-page-js" */)
}

