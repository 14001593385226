import styled from "@emotion/styled"
import { graphql, navigate, StaticQuery } from "gatsby"
import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react"
import { Box, Flex, Input } from "theme-ui"
import CrossIcon from "../../assets/icons/cross.svg"
import { InterfaceContext } from "../../context/interface-context"
import StoreContext from "../../context/store-context"
import Text from "../base/text/text"
import SearchIcon from "../../assets/icons/search-icon.svg"
import LongArrowRight from "../../assets/icons/long-arrow-right.svg"
import ArrowRight from "../../assets/icons/arrow-right.svg"
import { useWindowSize } from "../../hooks/useWindowSize"

const ProductNavContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.brown};
  flex-direction: column;
  position: fixed;
  height: 100%;
  z-index: 1021;
  transition: transform 0.5s ease-out;
  transform: translateX(-100%);

  ${(props) =>
    props.visible &&
    `
      transform: translateX(0);
    `}
`

const MobileHeader = styled(Flex)`
  height: 47px;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  padding-left: 12px;
  margin-bottom: 14px;
  position: relative;

  ${({ theme }) => theme.bp.desktop} {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 52px;
    width: 1px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.white};
  }

  .search-icon {
    height: 44px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .search-input {
    color: ${(props) => props.theme.colors.white};
    border: 0;
    position: relative;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 5px;

    &:focus {
      outline: none;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.white};
    }
  }

  .close-icon {
    width: 18px;
    cursor: pointer;
    fill: ${(props) => props.theme.colors.white};
    flex-shrink: 0;
    margin-right: 17px;
  }
`

const MobileItem = styled(Flex)`
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;

  ${({ theme }) => theme.bp.largeDesktop} {
    display: ${(props) => props.isHiddenOnDesktop && "none"};
  }

  .icon {
    width: 30px;
    height: 20px;

    &.expanded {
      transform: rotate(90deg);
    }
  }

  .arrow-right {
    margin-bottom: 4px;
  }

  .name {
    font-size: 45px;
    color: ${(props) => props.theme.colors.white};
    padding: 0;
    line-height: 1.3;
  }

  .region {
    font-size: 12px;
    border-bottom: 1px solid white;
    position: relative;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 2px;
    margin-bottom: 15px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 6px;
      background-color: white;
    }
  }
`

const Wrapper = styled(Box)`
  height: 100%;
`

const ItemList = styled(Box)`
  position relative;
  overflow: auto;

  &::before {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(168, 96, 61, 0) 0%, #a8603d 100%);
  }
`

const ExpandedShop = styled(Flex)`
  flex-direction: column;
  position: absolute;
  background: ${(props) => props.theme.colors.brown};
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 10;
  top: 0;
  transition: ${(props) =>
    props.screenSize === "mobile" && "transform 0.5s ease-out"};
  transform: translateX(-100%);

  ${(props) =>
    props.expanded &&
    `
      transform: translateX(0);
    `}

  .expanded-mobile-header {
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 14px;

    .text {
      color: ${(props) => props.theme.colors.white};
      font-size: 14px;
    }

    .arrow-left {
      transform: rotate(180deg);
      cursor: pointer;
      margin-left: 4px;
    }
  }

  .item {
    font-size: 18px;
    color: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 13px;
    padding-left: 16px;
    margin-bottom: 14px;
    margin-left: 0;

    ${({ theme }) => theme.bp.desktop} {
      margin-bottom: 0;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    svg {
      margin-left: 10px;
    }
  }
`

const ProductNav = () => {
  const { isProductNavOpen, hideDrawers, isRegionOpen, toggleRegionOpen } =
    useContext(InterfaceContext)
  const productNavRef = useRef()
  const windowSize = useWindowSize()

  const [search, setSearch] = useState("")
  const { cart } = useContext(StoreContext)

  const [shopExpanded, setShopExpanded] = useState(false)

  const handleClick = (node) => {
    if (node.slug) {
      hideDrawers()
      navigate(`/${node.slug}`)
      setShopExpanded(false)
    }
  }

  const handleHideDrawers = useCallback(() => {
    if (!isRegionOpen) {
      hideDrawers()
      setShopExpanded(false)
    }
  }, [hideDrawers, isRegionOpen])

  useEffect(() => {
    if (!isProductNavOpen) {
      return
    }

    if (windowSize.width >= 768) {
      setShopExpanded(true)
    }

    const handleOutsideClick = (e) => {
      if (productNavRef.current && !productNavRef.current.contains(e.target)) {
        handleHideDrawers()
      }
    }

    const escFunction = (event) => {
      if (event.key === "Escape") {
        handleHideDrawers()
      }
    }

    document.addEventListener("click", handleOutsideClick, true)
    document.addEventListener("keydown", escFunction, true)

    return () => {
      document.removeEventListener("click", handleOutsideClick, true)
      document.removeEventListener("keydown", escFunction, true)
    }
  }, [handleHideDrawers, isProductNavOpen])

  return (
    <StaticQuery
      query={graphql`
        {
          productNav: contentfulNavigationMenu(name: { eq: "Products" }) {
            items {
              ... on ContentfulCategory {
                name
                slug
                id
              }
            }
            name
            id
          }
        }
      `}
      render={({ productNav }) => (
        <ProductNavContainer
          ref={productNavRef}
          visible={isProductNavOpen}
          screenSize={windowSize.width <= 768 ? "mobile" : "desktop"}
          sx={{ flexBasis: ["90%", "33%"], minWidth: ["90%", "500px"] }}
        >
          <Wrapper>
            <MobileHeader>
              <CrossIcon className="close-icon" onClick={() => hideDrawers()} />
              <Input
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/search?q=${search}`)
                    hideDrawers()
                  }
                }}
                autoComplete="off"
                enterKeyHint="search"
                className="search-input"
                autoFocus
                borderNone={true}
                outline={true}
                sx={{ fontSize: 2 }}
              />
              <SearchIcon
                id="search-button"
                className="search-icon"
                onClick={() => {
                  navigate(`/search?q=${search}`)
                  hideDrawers()
                }}
              />
            </MobileHeader>
            <MobileItem
              isHiddenOnDesktop
              onClick={() => {
                setShopExpanded(!shopExpanded)
              }}
            >
              <Text className="name">Shop</Text>
              <LongArrowRight className="arrow-right" />
            </MobileItem>
            <ExpandedShop
              expanded={shopExpanded}
              screenSize={windowSize.width <= 768 ? "mobile" : "desktop"}
            >
              <MobileHeader className="expanded-mobile-header">
                <CrossIcon
                  className="close-icon"
                  onClick={() => hideDrawers()}
                />
                <Text className="text">Shop</Text>
                {windowSize.width <= 768 && (
                  <LongArrowRight
                    className="arrow-left"
                    onClick={() => setShopExpanded(false)}
                  />
                )}
              </MobileHeader>
              <ItemList>
                {productNav.items &&
                  productNav.items.map((node) => (
                    <MobileItem
                      ml={2}
                      key={node.id}
                      onClick={() => handleClick(node)}
                      className="item"
                    >
                      {node.name}

                      <ArrowRight />
                    </MobileItem>
                  ))}
              </ItemList>
            </ExpandedShop>
            <MobileItem
              isHiddenOnDesktop
              onClick={() => {
                navigate("/stories")
                hideDrawers()
              }}
            >
              <Text className="name">Stories</Text>
            </MobileItem>
            <MobileItem
              isHiddenOnDesktop
              onClick={() => {
                navigate("/about")
                hideDrawers()
              }}
            >
              <Text className="name">About</Text>
            </MobileItem>
            <MobileItem
              isHiddenOnDesktop
              onClick={() => toggleRegionOpen()}
              sx={{
                position: "absolute",
                bottom: "0",
                paddingRight: "0px !important",
              }}
            >
              <Text className="name region">{`${
                cart.countryName
              } (${cart.currencyCode.toUpperCase()})`}</Text>
            </MobileItem>
          </Wrapper>
        </ProductNavContainer>
      )}
    />
  )
}

export default ProductNav
