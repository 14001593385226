import React, { useEffect, useReducer } from "react"
import { createClient } from "../../util/client"

export const defaultCheckoutContext = {
  adding: false,
  cart: { items: [], currencyCode: "DKK" },
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

export const MedusaCheckoutContext = React.createContext(defaultCheckoutContext)

const setCart = (cart) => {
  if (cart.email && cart.customer_id) {
    if (window && window.analytics) {
      window.analytics.identify(
        cart.customer_id,
        {
          email: cart.email,
        },
        {
          integrations: {
            MailChimp: false,
          },
        }
      )
    }
  }

  return {
    ...cart,
    tax_rate: cart.region.tax_rate,
    currency_code: cart.region.currency_code,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setErrors":
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      }
    case "setLoadingPaySessions":
      return {
        ...state,
        isLoadingPaySessions: action.payload,
      }
    case "setLoading":
      return {
        ...state,
        isLoading: action.payload || false,
      }
    case "setCart":
      return {
        ...state,
        isLoading: false,
        cart: setCart(action.payload),
      }
    default:
      return state
  }
}

export const MedusaCheckoutProvider = ({ cartId, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultCheckoutContext)
  const client = createClient()

  const refreshCart = async (id) => {
    if (id) {
      return client.carts.retrieve(id).then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
    }

    return Promise.resolve({})
  }

  const initializePaymentSessions = async (id) => {
    return client.carts
      .createPaymentSessions(id)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  useEffect(() => {
    if (cartId) {
      refreshCart(cartId)
        .then(({ cart }) => {
          dispatch({ type: "setCart", payload: cart })
        })
        .catch((err) => {
          dispatch({ type: "setErrors", payload: err })
        })
    }
  }, [cartId])

  const update = (payload) => {
    return client.carts
      .update(state.cart.id, payload)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const addShippingMethod = (payload) => {
    return client.carts
      .addShippingMethod(state.cart.id, payload)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const setPaymentSession = (provider) => {
    return client.carts
      .setPaymentSession(state.cart.id, {provider_id: provider})
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const updatePaymentSession = (session) => {
    return client.carts
      .updatePaymentSession(state.cart.id, { session })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const getShippingOptions = () => {
    return client.shippingOptions
      .listCartOptions(state.cart.id)
      .then((data) => {
        const { shipping_options } = data

        return shipping_options.reduce((acc, next) => {
          if (acc[next.profile_id]) {
            acc[next.profile_id] = [...acc[next.profile_id], next]
          } else {
            acc[next.profile_id] = [next]
          }
          return acc
        }, {})
      })
  }

  const removeDiscount = (code) => {
    return client.carts.discounts
      .delete(state.cart.id, code)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) => {
        dispatch({ type: "setErrors", payload: err })
      })
  }

  const removeLineItem = (lineId) => {
    return client.carts.lineItems.delete(state.cart.id, lineId).then((data) => {
      dispatch({ type: "setCart", payload: data.cart })
      return data
    })
  }

  const updateLineItem = (lineId, quantity) => {
    return client.carts.lineItems
      .update(state.cart.id, lineId, { quantity })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const setCartFromObject = (newCart) => {
    dispatch({ type: "setCart", payload: newCart })
  }

  return (
    <MedusaCheckoutContext.Provider
      value={{
        ...state,
        update,
        setCartFromObject,
        addShippingMethod,
        initializePaymentSessions,
        setPaymentSession,
        updatePaymentSession,
        getShippingOptions,
        removeDiscount,
        removeLineItem,
        updateLineItem,
      }}
    >
      {children}
    </MedusaCheckoutContext.Provider>
  )
}
