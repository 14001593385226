import { useContext } from "react"
import StoreContext from "../../context/store-context"
import { formatMoneyAmount } from "../../util/prices"

const FreeShippingBar = () => {
  const { cart, freeShippingLimit } = useContext(StoreContext)

  if (!freeShippingLimit) {
    return ''
  }

  return 'Free shipping on all orders above ' +
      formatMoneyAmount(
        {
          amount: freeShippingLimit,
          currencyCode: cart.currencyCode,
        },
        2,
        cart.taxRate
      )
}

export default FreeShippingBar
