import { useCallback, useEffect, useState } from "react"

const useQuery = (initialQuery) => {
  const [data, setData] = useState({
    result: null,
    loading: false,
    status: null,
    load_time: null,
    error: null,
  })
  const [query, setQuery] = useState(initialQuery)

  const updateDeltaTime = useCallback(
    (started) =>
      setData((data) => ({ ...data, load_time: Date.now() - started })),
    []
  )

  const runQuery = useCallback(async () => {
    if (query) {
      setData((data) => ({ ...data, loading: true }))

      const started = Date.now()

      const updateDeltaInterval = setInterval(updateDeltaTime(started), 100)

      await query
        .then((data) => {
          setData({
            result: data,
            loading: false,
            load_time: Date.now() - started,
            status: 200,
            error: null,
          })
          clearInterval(updateDeltaInterval)
        })
        .catch((err) => {
          console.log(err)
          setData({
            result: null,
            loading: false,
            load_time: Date.now() - started,
            status: 400,
            error: err,
          })
          clearInterval(updateDeltaInterval)
        })
    }
  }, [query, updateDeltaTime])

  useEffect(() => {
    runQuery()
  }, [query, runQuery])

  return [data, setQuery]
}

export default useQuery
