import { useState } from "react"
import Medusa from "../services/api"

const useNewsletterSignup = () => {
  const [signingUp, setSigningUp] = useState(false)
  const [state, setState] = useState({
    signupComplete: false,
    email: "",
    userExist: null,
    error: null,
  })

  const handleEmailChange = (e) => {
    setState((state) => ({ ...state, email: e.target.value }))
  }

  const handleSignup = async () => {
    setSigningUp(true)
    const payload = {
      email: state.email,
      ids: {},
    }

    if (window.analytics && window.analytics.user) {
      payload.ids = {
        user_id: window.analytics.user().id(),
        anonymous_id: window.analytics.user().anonymousId(),
      }
    }

    try {
      const { data } = await Medusa.newsletter.signup(payload)

      setState({
        ...state,
        signupComplete: true,
        userExist: data.user_exists,
      })
    } catch (error) {
      setState((state) => ({ ...state, error: "Invalid email" }))
    }

    setSigningUp(false)
  }

  return {
    handleEmailChange,
    handleSignup,
    signingUp,
    state,
  }
}

export default useNewsletterSignup
