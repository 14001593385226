import styled from "@emotion/styled"
import React from "react"
import { Flex } from "theme-ui"

const StyledSpinner = styled(Flex)`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #245437;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Spinner = ({ sx, ...props }) => {
  return <StyledSpinner sx={{ ...sx }} {...props} />
}

export default Spinner
