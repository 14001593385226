export const colors = {
  black: "black",
  black_10: "#0000001A",
  black_30: "#0000004D",
  grey: "grey",
  lightBrown: "#e4cda4",
  cremeWhite: "#f8f4f1",
  almostWhite: "#e2e2e2",
  white: "white",
  pink: "pink",
  red: "red",
  orange: "#f56129",
  brown: "#a8603d",
  yellow: "#dbff00",
  darkGreen: "#245437",
  opaqueGreen: "rgba(36,84,55,0.3)",
  lightGreen: "#7E9786",
  background: "#E7E5E1",
  hover: "#e2e2e2",
}
