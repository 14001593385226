import React from "react"
import Image from "../components/base/image"
import Video, { VimeoVideo } from "../components/base/video"
import { MediaType } from "./types"

export const handleMedia = (media, options = {}) => {
  const { vh, imageClick } = options

  const handleContentfulMedia = (media) => {
    const type = media.file.contentType
    if (type === MediaType.MP4 || type === MediaType.TEXT) {
      return <Video key={media.id} src={media.file.url} />
    } else if (
      type === MediaType.JPG ||
      type === MediaType.PNG ||
      type === MediaType.WEBP
    ) {
      return (
        <Image key={media.id} primary={media} vh={vh} onClick={imageClick} />
      )
    }
  }

  const handleUrl = (url, id) => {
    if (url.includes("youtube.com")) {
      return <Video src={url} key={id} />
    } else if (url.includes("vimeo.com")) {
      return <VimeoVideo src={url} key={id} />
    } else {
      return <Video src={url} key={id} />
    }
  }

  if (media.contentfulMedia) {
    return handleContentfulMedia(media.contentfulMedia)
  } else if (media.url) {
    return handleUrl(media.url, media.id)
  } else {
    return handleContentfulMedia(media)
  }
}

// const handleUrlEmbeddedID = (url) => {
//   switch (url) {
//     case url.includes("youtube"):
//       return url.split("v=")[1]
//     case url.includes("vimeo"):
//       return url.split(".com/")[1]
//   }
// }

export const handleItemsInCart = (cart) => {
  if (!cart) {
    return 0
  }

  return cart.items.reduce((acc, next) => {
    return acc + next.quantity
  }, 0)
}
