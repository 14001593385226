import { colors } from "./colors"
import { borders } from "./borders"
const base = {
  fontWeight: "700",
  lineHeight: "14px",
  borderRadius: "0px",
  alignText: "center",
}

export const buttons = {
  primary: {
    ...base,
    color: colors.background,
    backgroundColor: colors.darkGreen,
  },
  cta: {
    ...base,
    borderRadius: "0px",
    color: colors.white,
    backgroundColor: colors.black,
  },
  option: {
    ...base,
    color: colors.darkGreen,
    backgroundColor: colors.background,
    border: borders.thin,
  },
  disabled: {
    ...base,
    color: colors.darkGreen,
    backgroundColor: colors.background,
    border: borders.thin,
  },
  loading: {
    ...base,
    backgroundColor: "#858c87",
  },
  default: {
    ...base,
  },
}
