export const getVariantPrice = (variant, currencyCode) => {
  if (!variant || !currencyCode) {
    return undefined
  }

  if (!variant?.prices?.length) {
    return undefined
  }

  const price = variant.prices.find(
    (d) => d.currency_code.toLowerCase() === currencyCode.toLowerCase()
  ).amount

  if (price) {
    return price / 100
  }
  return undefined
}

export const getLowestVariantPrice = (product, currencyCode) => {
  if (!product || !currencyCode || !product?.variants?.length) {
    return undefined
  }

  const price = product.variants.reduce((acc, next) => {
    const vPrice = getVariantPrice(next, currencyCode)
    if (vPrice < acc) {
      acc = vPrice
    }
    return acc
  }, Number.MAX_SAFE_INTEGER)

  return price
}

const score = (opt) => {
  switch (opt) {
    case "XXS":
      return 0
    case "XS":
      return 1
    case "S":
      return 2
    case "M":
      return 3
    case "L":
      return 4
    case "XL":
      return 5
    case "XXL":
      return 6
    default:
      return 7
  }
}

export const sortVariants = ({ variants }) => {
  let sorted = []
  const type = variants[0].options[0].value
  const isInt = parseInt(type)
  if (isNaN(isInt)) {
    const sortingAlgo = (a, b) => {
      const temp1 = a.options[0].value.trim()
      const temp2 = b.options[0].value.trim()
      const as = score(temp1)
      const bs = score(temp2)
      return as - bs
    }

    sorted = variants.sort(sortingAlgo)
  } else {
    const sortingAlgo = (a, b) => {
      const at = parseInt(a.options[0].value)
      const bt = parseInt(b.options[0].value)
      return at - bt
    }
    sorted = variants.sort(sortingAlgo)
  }
  return sorted
}
