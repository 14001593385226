import React, { useContext, useEffect, useRef } from "react"
import { Flex, Box } from "theme-ui"
import styled from "@emotion/styled"

import { InterfaceContext } from "../../context/interface-context"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"

const Wrapper = styled(Box)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
  width: 100%;
  height: 100%;
  z-index: 300;
  background: rgba(255, 255, 255, 0.8); /* give opacity to bg color only */
`

const Container = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
  z-index: 200;
  height: 100%;
  width: 100%;
  opacity: 100%;

  overflow: scroll;
`

export const CloseContainer = styled(Flex)`
  color: ${(props) => props.theme.colors.background};
  position: absolute;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;

  z-index: 200;
  text-decoration: underline;
  padding: ${(props) => props.theme.space[3]}px;
`

const Modal = () => {
  const { modalVisible, toggleModal, modalContent } =
    useContext(InterfaceContext)

  const [, setScrollable] = useDisableBackgroundScroll()

  const modalRef = useRef()

  const handleLayoutClicked = (e) => {
    if (modalVisible) {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        toggleModal()
        setScrollable(true)
      }
    }
  }

  useEffect(() => {
    const handleEscape = (event) => {
      // Escape key
      if (event.keyCode === 27) {
        setScrollable(true)
        toggleModal()
      }
    }

    if (modalVisible) {
      document.addEventListener("keydown", handleEscape, false)
    }

    return () => document.removeEventListener("keydown", handleEscape, false)
  }, [modalVisible, toggleModal, setScrollable])

  return (
    <Wrapper onClick={handleLayoutClicked} visible={modalVisible}>
      <Container visible={modalVisible} ref={modalRef}>
        {modalVisible && modalContent}
      </Container>
    </Wrapper>
  )
}

export default Modal
