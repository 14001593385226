import qs from "query-string"
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import useNotifications from "../hooks/useNotifications"
import { createClient } from "../util/client"
import { expire1hour } from "../util/time"

const DEFAULT_SHIPPING_PROFILE =
  process.env.GATSBY_DEFAULT_SHIPPING_PROFILE || "sp_01F2RSY6RVGKDHF57MFKBMPP75"

export const defaultStoreContext = {
  adding: false,
  cart: {
    items: [],
    countryName: "Denmark",
    currencyCode: "DKK",
  },
  freeShippingLimit: null,
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  getInventoryQuantities: () => {},
  getShippingOptions: () => {},
  addDiscount: () => {},
  addGiftCard: () => {},
  removeDiscount: () => {},
  removeGiftCard: () => {},
  dispatch: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)
export default StoreContext

const setCart = (cart) => {
  if (cart.customer_id) {
    if (window.analytics) {
      window.analytics.identify(cart.customer_id)
    }
  }

  const countryCode = cart.shipping_address?.country_code

  return {
    id: cart.id,
    loading: false,
    taxRate: parseFloat(cart.region.tax_rate),
    countryCode,
    countryName: countryCode
      ? cart.region.countries.find((country) => country.iso_2 === countryCode)
          ?.display_name || "Denmark"
      : "Denmark",
    currencyCode: cart.region.currency_code,
    items: cart.items,
    total: cart.total,
    discounts: cart.discounts,
    taxTotal: cart.tax_total || 0,
    subtotal: cart.subtotal || 0,
    shippingTotal: cart.shipping_total || 0,
    region: cart.region,
    discountTotal: cart.discount_total || 0,
    freeShippingLimit: cart.freeShippingLimit,
    metadata: cart.metadata,
    context: cart.context,
    ...cart,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setCart":
      if (!action.payload) {
        localStorage.removeItem("medusa::cache")
        return {
          ...state,
          cart: { items: [], countryName: "Denmark", currencyCode: "DKK" },
        }
      }

      if (localStorage) {
        localStorage.setItem("medusa::cache", JSON.stringify(action.payload))
      }

      return {
        ...state,
        cart: setCart(action.payload),
      }
    case "setFreeShippingLimit":
      // Set or update cache
      localStorage.setItem(
        `pg:fs:${action.payload.regionId}`,
        JSON.stringify(action.payload)
      )
      return {
        ...state,
        freeShippingLimit: action.payload.freeShippingLimit,
      }

    default:
      return state
  }
}

const client = createClient()

export const StoreProvider = ({ children }) => {
  const [checkInterval, setCheckInterval] = useState(null)
  const [gaChecked, setGaChecked] = useState(0)
  const [state, dispatch] = useReducer(reducer, defaultStoreContext)
  const stateCartId = useRef()
  const { pushNotification } = useNotifications()

  useEffect(() => {
    if (checkInterval && gaChecked > 4) {
      clearInterval(checkInterval)
    }
  }, [gaChecked, checkInterval])

  useEffect(() => {
    if (!state.cart?.id) {
      return
    }

    setCheckInterval(
      setInterval(() => {
        setGaChecked((checkCount) => {
          if (window.ga) {
            window.ga(function (tracker) {
              const gaId = tracker.get("clientId")

              if (state.cart?.context && gaId) {
                if (state.cart.context.ga_id !== gaId) {
                  client.carts
                    .update(state.cart.id, {
                      context: {
                        ga_id: gaId,
                      },
                    })
                    .then((data) => {
                      dispatch({ type: "setCart", payload: data.cart })
                      return data
                    })
                }
              }
            })
            return 5
          }
          return checkCount + 1
        })
      }, 2000)
    )
  }, [state.cart.id])

  useEffect(() => {
    stateCartId.current = state.cart.id
  }, [state.cart.id])

  useEffect(() => {
    const ipLookupTempEror = localStorage.getItem("ipl_clear")
    const isPGCleared = localStorage.getItem("pg_clear")
    if (!ipLookupTempEror) {
      localStorage.setItem("ipl_clear", "t")
      createCart()
    } else if (!isPGCleared) {
      localStorage.setItem("pg_clear", "t")
      createCart()
    } else {
      const temp = qs.parse(window.location.search)
      if (temp.ct) {
        refreshCart(temp.ct)
      } else {
        if (localStorage) {
          const cachedCart = localStorage.getItem("medusa::cache")
          if (cachedCart) {
            const cart = JSON.parse(cachedCart)
            if (cart.metadata && cart.metadata.is_done) {
              createCart()
            } else {
              dispatch({ type: "setCart", payload: cart })
              getFreeShippingLimit(cart.region_id)
            }
          } else {
            createCart()
          }
        }
      }
    }
  }, [createCart, getFreeShippingLimit, refreshCart])

  const refreshCart = useCallback(async (cartId) => {
    if (cartId) {
      return client.carts
        .retrieve(cartId)
        .then((data) => {
          dispatch({ type: "setCart", payload: data.cart })
        })
        .catch(() => {
          return client.carts.create({}).then((data) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
        })
    } else {
      if (stateCartId.current) {
        return client.carts
          .retrieve(stateCartId.current)
          .then((data) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
          .catch(() => {
            return client.carts.create().then((data) => {
              dispatch({ type: "setCart", payload: data.cart })
            })
          })
      }
    }
  }, [])

  const createCart = useCallback(async () => {
    // Check shipping options cache and/or create it
    return client.carts.create().then((data) => {
      dispatch({ type: "setCart", payload: data.cart })
      getFreeShippingLimit(data.cart.region_id)
    })
  }, [getFreeShippingLimit])

  const getFreeShippingLimit = useCallback(async (regionId) => {
    // Get cache
    const data = localStorage.getItem(`pg:fs:${regionId}`)

    let result

    const now = new Date()

    if (data) {
      const regionCache = JSON.parse(data)
      // Check exiryDate
      if (regionCache.expiryDate > now.getUTCMilliseconds()) {
        // Create new cache item
        result = regionCache
      }
    }
    if (!result) {
      result = await client.shippingOptions
        .list({ region_id: regionId })
        .then((data) => {
          const freeShippingLimit = data.shipping_options.reduce(
            (acc, nextValue) => {
              if (nextValue.profile_id !== DEFAULT_SHIPPING_PROFILE) {
                return acc
              }

              // If we find a free shipping limit, find the lowest requirement
              if (nextValue.amount === 0) {
                const limit = nextValue.requirements.find(
                  (r) => r.type === "min_subtotal"
                )

                if (typeof limit === "undefined") {
                  acc = 0
                } else {
                  if (typeof acc === "undefined" || acc > limit.amount) {
                    acc = limit.amount
                  }
                }
              }
              return acc
            },
            undefined
          )

          // Add 24 hours from today as expiry date
          let expiryDate = parseInt(now.getTime()) + 24 * 60 * 60 * 1000

          return {
            regionId: regionId,
            freeShippingLimit: freeShippingLimit,
            expiryDate: expiryDate,
          }
        })
    }

    dispatch({ type: "setFreeShippingLimit", payload: result })
  }, [])

  const clearCart = () => {
    createCart()
  }

  const getInventoryQuantities = async (productId) => {
    const productVariantsQuery = async () =>
      client.products.retrieve(productId).then((data) => {
        const variants = data.product.variants.reduce((acc, next) => {
          acc[next.id] = { inventory_quantity: next.inventory_quantity }

          return acc
        }, {})

        return {
          expiryDate: expire1hour(),
          variants: variants,
        }
      })

    const productVariants = await productVariantsQuery()

    return productVariants
  }

  const addDiscount = async (discountCode) => {
    return await client.carts
      .update(state.cart.id, {
        discounts: [{ code: discountCode }],
      })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data.cart
      })
  }

  const addGiftCard = async (giftCardCode) => {
    return await client.carts
      .update(state.cart.id, {
        gift_cards: [{ code: giftCardCode }],
      })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data.cart
      })
  }

  const removeDiscount = async (discountCode) => {
    return await client.carts
      .deleteDiscount(state.cart.id, discountCode)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data.cart
      })
  }

  const removeGiftCard = async () => {
    return await client.carts
      .update(state.cart.id, { gift_cards: [] })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data.cart
      })
  }

  const addVariantToCart = async ({ variantId, quantity, metadata }) => {
    return await client.carts.lineItems
      .create(state.cart.id, {
        variant_id: variantId,
        quantity: quantity,
        metadata: metadata,
      })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data.cart
      })
  }

  const removeLineItem = (lineId) => {
    return client.carts.lineItems.delete(state.cart.id, lineId).then((data) => {
      dispatch({ type: "setCart", payload: data.cart })
      return data
    })
  }

  const updateLineItem = async ({ lineId, quantity }) => {
    return client.carts.lineItems
      .update(state.cart.id, lineId, { quantity: quantity })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
      .catch((err) =>
        pushNotification({
          id: err,
          message: "Couldn't add: Not enough in stock",
        })
      )
  }

  const setRegion = (regionId, countryCode) => {
    return client.carts
      .update(state.cart.id, {
        region_id: regionId,
        country_code: countryCode,
      })
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        getFreeShippingLimit(data.cart.region_id)
        return data
      })
  }

  const addShippingMethod = (payload) => {
    return client.carts
      .setShippingMethod(state.cart.id, payload)
      .then((data) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const getShippingOptions = async (query) => {
    const data = await client.shippingOptions.list(query).then((data) => data)

    if (data) {
      return data.shipping_options
    } else {
      return undefined
    }
  }

  return (
    <StoreContext.Provider
      value={{
        ...state,
        addShippingMethod,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        setRegion,
        clearCart,
        getInventoryQuantities,
        getShippingOptions,
        addDiscount,
        addGiftCard,
        removeDiscount,
        removeGiftCard,
        dispatch,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
