import React from "react"
import { Text } from "theme-ui"
import { formatVariantPrice } from "../../util/prices"

const VariantPrice = ({ variant, cart }) => {
  if (!variant) {
    return null
  }

  if (variant.calculated_price < variant.original_price) {
    return (
      <>
        <Text mr={2}>{formatVariantPrice(cart, variant, true)}</Text>
        <Text sx={{ textDecoration: "line-through" }}>
          {formatVariantPrice(cart, variant, false)}
        </Text>
      </>
    )
  }

  return <Text>{formatVariantPrice(cart, variant, true)}</Text>
}

export default VariantPrice
