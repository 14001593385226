import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import { Box, Flex, Grid, Text } from "theme-ui"
import StoreContext from "../../context/store-context"
import useProductVariants from "../../hooks/use-product-variants"
import useQuery from "../../hooks/useQuery"
import { trackAddToCart } from "../../services/analytics"
import { createClient } from "../../util/client"
import { sortVariants } from "../../util/product-functions"
import Button from "../base/buttons"
import Select from "../dropdown"
import VariantPrice from "../product/price"

const ItemsContainer = styled("div")`
  display: flex;
  flex-direction: column;
`

const CartUpsell = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulUpsellProducts {
        nodes {
          products {
            variants {
              objectId
              options {
                value
              }
              prices {
                currency_code
                amount
              }
            }
            objectId
            medusaTitle
            thumbnail {
              gatsbyImageData
            }
            color {
              name
            }
          }
        }
      }
    }
  `)

  const { cart } = useContext(StoreContext)

  return (
    <Box
      sx={{
        paddingX: "30px",
        paddingBottom: "22px",
      }}
    >
      <Box
        sx={{
          paddingY: "22px",
        }}
      >
        <Text>This might be a great match</Text>
      </Box>
      <ItemsContainer>
        {data.allContentfulUpsellProducts.nodes.map((node) => {
          return node.products.map((product, i) => {
            return (
              <UpsellItem
                item={product}
                cart={cart}
                isLast={i === node.products.length - 1}
                key={i}
              />
            )
          })
        })}
      </ItemsContainer>
    </Box>
  )
}

const ThumbnailContainer = styled("div")`
  width: 100%;
`

const AddButton = styled(Button)`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  span {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${(props) => props.theme.colors.darkGreen};
    line-height: 0;
  }
`

const Item = styled("div")`
  border-bottom: 1px solid ${(props) => props.theme.colors.opaqueGreen};
  padding: 15px 0;

  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.colors.opaqueGreen};
  }
`

const UpsellItem = ({ item, cart, isLast = false }) => {
  const [selected, setSelected] = useState(undefined)
  const [options, setOptions] = useState([])
  const [soldOut, setSoldOut] = useState(false)
  const { dispatch } = useContext(StoreContext)

  const { variants } = useProductVariants({
    productId: item.objectId,
    contentfulProduct: item,
  })

  const client = createClient()
  const [queryData, setQuery] = useQuery(null)

  // If item inventory is less or equal to the amount added to the cart, then disable the variant to prevent adding more to cart
  const disableOnInsuficientStock = (variantId) => {
    const tmp = options
    const toAdjust = options.findIndex((opt) => opt.value === variantId)

    if (toAdjust > -1) {
      tmp[toAdjust].disabled = true

      setOptions(tmp)
    }
  }

  const handleAddToCartClicked = async () => {
    let size = selected?.label || ""

    if (size === "Default Variant") {
      size = ""
    }

    setQuery(
      client.carts.lineItems
        .create(cart.id, {
          variant_id: selected.value,
          metadata: { color: item?.color?.name, size },
          quantity: 1,
        })
        .then((data) => {
          const variant = item.variants.find(
            (variant) => variant.objectId === selected.value
          )

          const lineItem = data.cart.items.find(
            (li) => li.variant_id === selected.value
          )

          if (lineItem && variant.inventory_quantity >= lineItem?.quantity) {
            disableOnInsuficientStock(selected.value)
          }

          trackAddToCart(cart, item, variant, 1)
          dispatch({ type: "setCart", payload: data.cart })
          setSelected(undefined)
        })
        .catch((err) => {
          if (err.response.data.code === "insufficient_inventory") {
            disableOnInsuficientStock(selected.value)
          }

          setSelected(undefined)
        })
    )
  }

  useEffect(() => {
    const buildOptions = async () => {
      if (!item) {
        return []
      }

      if (variants?.length) {
        const contentfulVariants = sortVariants({ variants: item.variants })

        const opts = contentfulVariants.map((variant) => {
          const lineItem = cart?.items?.find(
            (li) => li.variant_id === variant.objectId
          )

          const vnt = variants.find((v) => v.objectId === variant.objectId)

          const isDisabled =
            lineItem?.quantity >= vnt.inventory_quantity ||
            vnt.inventory_quantity === 0

          return {
            label: variant.options[0].value,
            value: variant.objectId,
            disabled: isDisabled,
            details: {},
          }
        })
        setOptions(opts)
      }
    }

    buildOptions()
  }, [item, cart, variants])

  // If there is only one variant, select it by default
  useEffect(() => {
    if (options && options.length === 1) {
      setSelected(options[0])
    }
  }, [options])

  useEffect(() => {
    if (options) {
      const allSoldOut = options.every((opt) => opt.disabled)

      setSoldOut(allSoldOut)
    }
  }, [options])

  const handleSelect = (variant) => {
    setSelected(variant)
  }

  const title = item.medusaTitle.split(" - ")
  const name = title.pop()
  const color = title.pop()

  // If all variants are sold out then don't render the item
  if (soldOut) {
    return null
  }

  return (
    <Item>
      <Grid
        sx={{
          gap: "12px",
          gridTemplateColumns: "17% 1fr",
        }}
      >
        <ThumbnailContainer>
          <GatsbyImage image={item.thumbnail.gatsbyImageData} />
        </ThumbnailContainer>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Flex
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                sx={{
                  fontSize: 1,
                }}
              >
                {color}
              </Text>
              <Text
                sx={{
                  fontSize: 1,
                }}
              >
                {name}
              </Text>
            </Flex>
            <AddButton
              variant={!selected ? "disabled" : "primary"}
              disabled={queryData?.loading || !selected}
              loading={queryData?.loading}
              onClick={handleAddToCartClicked}
            >
              <Text sx={{ fontSize: 2 }}>+ Add</Text>
            </AddButton>
          </Flex>
          <Flex
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {options.length > 1 ? (
              <Select
                value={selected}
                options={options}
                valueRender={() => selected?.label}
                valuePlaceholder="Size"
                onOptionClick={handleSelect}
                showArrow
                sx={{
                  maxWidth: "75px",
                  height: "33px",
                  fontSize: 1,
                  ".select-value": {
                    borderColor: "rgba(36,84,55,0.3) !important", // Bug: Can't use theme.colors here
                  },
                }}
                openUp={isLast}
                variant="opaqueGreen"
              />
            ) : (
              <Text
                sx={{
                  fontSize: 0,
                }}
              >
                One Size
              </Text>
            )}
            <Box>
              <VariantPrice cart={cart} variant={variants[0]} />
            </Box>
          </Flex>
        </Flex>
      </Grid>
    </Item>
  )
}

export default CartUpsell
