import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"

const StyledLink = styled(GatsbyLink)`
  color: ${(props) => props.theme.colors.darkGreen};
  text-decoration: none;
`

const Link = ({ children, to, ...props }) => {
  return (
    <StyledLink to={to} {...props}>
      {children}
    </StyledLink>
  )
}

export default Link
