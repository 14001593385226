import styled from "@emotion/styled"
import { Box } from "theme-ui"

export const MobileOnly = styled.div`
  display: block;

  ${({ theme }) => theme.bp.desktop} {
    display: none;
  }
`

export const DesktopOnly = styled(Box)`
  display: none;

  ${({ theme }) => theme.bp.desktop} {
    display: flex;
  }
`
