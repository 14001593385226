import React from "react"
import Layout from "./src/components/layout"
import { ThemeProvider as Provider } from "./src/theme"

const ONE_TRUST_SRC = process.env.GATSBY_ONE_TRUST || ""

export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
}) => {
  const headComponents = getHeadComponents()

  headComponents.push(
    React.createElement("script", {
      key: "gtm",
      type: "text/javascript",
      dangerouslySetInnerHTML: {
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://load.events.palmes.co/lrenmbfd.js?st='+i+dl+'';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','PP3M932F');`,
      },
    })
  )

  headComponents.push(
    React.createElement("script", {
      key: "onetrust",
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      charSet: "UTF-8",
      "data-domain-script": ONE_TRUST_SRC,
    })
  )
  headComponents.push(
    React.createElement(
      "script",
      {
        key: "onetrust-optanon",
        type: "text/javascript",
      },
      ["function OptanonWrapper() { }"]
    )
  )
  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "_Z1ReAsBUehm5flkm-OqzqRmEjco1xB4YJ4FrPwutZI",
    })
  )

  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "VI5xdd4g5HIVMerL36PMdyg5AWjJfO8dnld1b5EWf4c",
    })
  )

  replaceHeadComponents(headComponents)
}

export const onRenderBody = ({ setPreBodyComponents }) => {
  const gtmNoscript = `<iframe src="https://load.events.palmes.co/ns.html?id=GTM-PP3M932F" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  setPreBodyComponents([
    <noscript key="gatsby-plugin-google-tagmanager-noscript" dangerouslySetInnerHTML={{ __html: gtmNoscript }} />,
  ]);
};

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}

/**
 * @param {import('gatsby').WrapPageElementBrowserArgs<DataType, PageContext, LocationState>} args
 * @returns
 */
export const wrapPageElement = ({ element }) => {
  let pathname = element.props.location?.pathname;

  if (typeof pathname !== "string") {
    pathname = '/';
  }

  const handleHideFooter = () => {
    const matchingUrls = ["/cart", "/done", "/checkout"]

    if (
      matchingUrls.some((url) =>
        pathname.startsWith(url)
      )
    ) {
      return true
    }

    return false
  }

  const handleRemoveExtraSpacing = () => {
    const matchingUrls = ["/checkout", "/cart"]

    if (
      pathname === "/" ||
      matchingUrls.some((url) =>
        pathname.startsWith(url)
      )
    ) {
      return true
    }

    return false
  }

  const removeExtraSpacing = handleRemoveExtraSpacing()
  const hideFooter = handleHideFooter()

  return (
    <Layout hideFooter={hideFooter} removeExtraSpacing={removeExtraSpacing}>
      {element}
    </Layout>
  )
}
