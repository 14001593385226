import { useNotificationContext } from "../context/notification-context"

const useNotifications = () => {
  const { notificationList, setNotificationList } = useNotificationContext()

  const pushNotification = ({ id, type, message, time, ...rest }) => {
    setNotificationList([
      ...notificationList,
      { id: id, type: type, message: message, time: time, ...rest },
    ])
  }

  return { pushNotification }
}

export default useNotifications
