export const ContentfulTypes = {
  Tile: "ContentfulTile",
  TileSection: "ContentfulTilesection",
  Hero: "ContentfulHeor",
}

export const MediaType = {
  JPG: "image/jpeg",
  PNG: "image/png",
  WEBP: "image/webp",
  GIF: "image/gif",
  MP4: "video/mp4",
  TEXT: "text/html",
}
