import styled from "@emotion/styled"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, Flex } from "theme-ui"
import { InterfaceContext } from "../../context/interface-context"
import StoreContext from "../../context/store-context"
import { createClient } from "../../util/client"
import { MobileOnly } from "../base"
import Text from "../base/text/text"

const RegionContainer = styled(Box)`
  display: ${(props) => (props.visible ? `flex` : `none`)};
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.darkGreen};
  flex-direction: column;
  position: fixed;
  height: 100%;
  border-left: ${(props) => props.theme.borders.darkGreen};
  z-index: 2000;
  align-self: flex-end;

  ${(props) => props.theme.bp.desktop} {
    height: calc(100% - ${(props) => props.theme.height[1]}px);
    margin-top: ${(props) => props.theme.height[1]}px;
  }
`

const Wrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
  box-shadow: ${({ theme }) => theme.shadows.cartShadow};
`

const SearchContainer = styled(Flex)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .search {
    border-bottom: ${(props) => props.theme.borders.darkGreen};
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 4%;

    .icon {
      align-self: center;
    }
  }
`

const SearchBar = styled.input`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 3%;
  aling-self: center;
  color: ${(props) => props.theme.colors.darkGreen};

  border: none;

  background-color: transparent;

  &:focus {
    outline: none;
  }
`

const Countries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 7%;
  margin: ${(props) => props.theme.space[3]}px 0;
  max-height: 100%;
  overflow: scroll;
`

const Country = styled.div`
  line-height: 1.25;
  margin-bottom: ${(props) => props.theme.space[3]}px;

  cursor: pointer;
`

const Info = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  padding: 12px 7%;
  border-top: ${(props) => props.theme.borders.darkGreen};
  margin-top: 12px;
`

const client = createClient()

const RegionDrawer = () => {
  const [countries, setCountries] = useState({
    all: [],
    visibleCountries: [],
  })
  const [searchValue, setSearchValue] = useState("")
  const searchRef = useRef(null)
  const containerRef = useRef(null)

  const { isRegionOpen, hideRegionDrawer } = useContext(InterfaceContext)
  const { setRegion } = useContext(StoreContext)

  useEffect(() => {
    if (isRegionOpen && searchRef) {
      searchRef.current.focus()
    }
    // add when mounted
  }, [isRegionOpen])

  useEffect(() => {
    client.regions.list().then((data) => {
      const countries = data.regions.reduce((acc, next) => {
        const result = next.countries.map((c) => {
          return {
            country_id: c.id,
            reg_id: next.id,
            name: c.name,
            country_code: c.iso_2,
            display_name: c.display_name,
            currency_code: next.currency_code,
          }
        })

        return acc.concat(result)
      }, [])

      countries.sort((a, b) => a.display_name.localeCompare(b.display_name))
      if (countries) {
        setCountries({ all: countries, visibleCountries: countries })
      }
    })
  }, [])

  const handleSearch = (e) => {
    let searchValue = e.target.value
    setSearchValue(searchValue)

    let visible = countries.all.filter((country) => {
      let countryName = country.name.toLowerCase()
      return countryName.startsWith(searchValue.toLowerCase())
    })

    setCountries({ ...countries, visibleCountries: visible })
  }

  const handleSelectCountry = (country) => {
    if (country) {
      setRegion(country.reg_id, country.country_code)
      hideRegionDrawer()
    }
  }

  return (
    <RegionContainer
      sx={{ width: ["100%", "45%"] }}
      visible={isRegionOpen}
      ref={containerRef}
      onMouseLeave={() => {
        if (window.innerWidth >= 768) {
          hideRegionDrawer()
        }
      }}
    >
      <Wrapper>
        <SearchContainer>
          <div className="search">
            <SearchBar
              ref={searchRef}
              type="text"
              name="search"
              placeholder="Search Country"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
          <Countries>
            {countries.visibleCountries &&
              countries.visibleCountries.map((country) => {
                return (
                  <Country
                    key={`country-${country.country_id}`}
                    onClick={() => handleSelectCountry(country)}
                  >
                    {country.display_name}
                  </Country>
                )
              })}
          </Countries>
        </SearchContainer>
        <Info>
          Choose your shipping location in order to see correct pricing,
          delivery times and shipping costs.
        </Info>
        <MobileOnly>
          <Flex sx={{ justifyContent: "center" }} py={3}>
            <Text
              onClick={() => hideRegionDrawer()}
              sx={{ textDecoration: "underline" }}
            >
              Close
            </Text>
          </Flex>
        </MobileOnly>
      </Wrapper>
    </RegionContainer>
  )
}

export default RegionDrawer
