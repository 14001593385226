import React, { useContext, useReducer } from "react"

export const defaultInterfaceContext = {
  isCartOpen: false,
  isFilterOpen: false,
  isRegionOpen: false,
  isSearchOpen: false,
  isProductNavOpen: false,
  modalVisible: false,
  modalContent: null,
  scrollable: true,
  header: "",
  hideShippingBar: false,
  setHeader: () => {},
  setModalContent: () => {},
  setRegion: () => {},
  setScrollable: () => {},
  setHideShippingBar: () => {},
  hideDrawers: () => {},
  hideSearchDrawer: () => {},
  hideRegionDrawer: () => {},
  hideProductNavDrawer: () => {},
  showCart: () => {},
  showFilter: () => {},
  showRegion: () => {},
  showProductNav: () => {},
  toggleSearch: () => {},
  toggleModal: () => {},
  toggleCart: () => {},
  toggleFilter: () => {},
  toggleProductNav: () => {},
  toggleRegionOpen: () => {},
}

export const InterfaceContext = React.createContext(defaultInterfaceContext)

export default React.createContext(defaultInterfaceContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setModalContent":
      return {
        ...state,
        modalContent: action.payload,
      }

    case "setRegion":
      return {
        ...state,
        region: action.payload,
      }

    case "setScrollable":
      return {
        ...state,
        scrollable: action.payload,
      }

    case "setHideShippingBar":
      return {
        ...state,
        hideShippingBar: action.payload,
      }

    case "hideDrawers":
      return {
        ...state,
        isFilterOpen: false,
        isLoginOpen: false,
        isCartOpen: false,
        isRegionOpen: false,
        isSearchOpen: false,
        isProductNavOpen: false,
      }

    case "setHeader":
      return {
        ...state,
        header: action.payload,
      }

    case "showCart":
      return {
        ...state,
        isFilterOpen: false,
        isCartOpen: true,
        isProductNavOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
      }

    case "showFilter":
      return {
        ...state,
        isFilterOpen: true,
        isCartOpen: false,
        isProductNavOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
      }

    case "showRegion":
      return {
        ...state,
        isFilterOpen: false,
        isCartOpen: false,
        isLoginOpen: false,
        isRegionOpen: true,
      }

    case "showProductNav":
      return {
        ...state,
        isProductNavOpen: true,
      }

    case "toggleModal":
      return {
        ...state,
        isFilterOpen: false,
        isCartOpen: false,
        isRegionOpen: false,
        isSearchOpen: false,
        modalVisible: !state.modalVisible,
      }

    case "toggleRegionOpen":
      return {
        ...state,
        isFilterOpen: false,
        isCartOpen: false,
        isLoginOpen: false,
        isSearchOpen: false,
        isRegionOpen: !state.isRegionOpen,
      }

    case "toggleCart":
      return {
        ...state,
        isFilterOpen: false,
        isProductNavOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
        isSearchOpen: false,
        isCartOpen: !state.isCartOpen,
      }

    case "toggleFilter":
      return {
        ...state,
        isFilterOpen: true,
        isProductNavOpen: false,
        isLoginOpen: false,
        isRegionOpen: false,
        isSearchOpen: false,
        isFilterOpen: !state.isFilterOpen,
      }

    case "toggleSearch":
      return {
        ...state,
        isFilterOpen: false,
        isCartOpen: false,
        isRegionOpen: false,
        modalVisible: false,
        isSearchOpen: !state.isSearchOpen,
      }
    case "toggleProductNav":
      return {
        ...state,
        isProductNavOpen: !state.isProductNavOpen,
        isFilterOpen: false,
        isCartOpen: false,
        isRegionOpen: false,
        isLoginOpen: false,
      }
    case "hideRegionDrawer":
      return {
        ...state,
        isRegionOpen: false,
      }
    case "hideSearchDrawer":
      return {
        ...state,
        isSearchOpen: false,
      }
    case "hideProductNav":
      return {
        ...state,
        isProductNavOpen: false,
      }
  }
}

export const InterfaceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const setModalContent = (content) => {
    if (content) {
      dispatch({ type: "setModalContent", payload: content })
    }
  }

  const hideDrawers = () => {
    dispatch({ type: "hideDrawers" })
  }

  const hideSearchDrawer = () => {
    dispatch({ type: "hideSearchDrawer" })
  }
  const hideRegionDrawer = () => {
    dispatch({ type: "hideRegionDrawer" })
  }
  const hideProductNav = () => {
    dispatch({ type: "hideProductNav" })
  }

  const setHeader = (header) => {
    if (header) {
      dispatch({ type: "setHeader", payload: header })
    }
  }

  const setRegion = (region) => {
    if (region) {
      dispatch({ type: "setRegion", payload: region })
    }
  }

  const setScrollable = (scrollable) => {
    dispatch({ type: "setScrollable", payload: scrollable })
  }

  const setHideShippingBar = (shouldHide) => {
    dispatch({ type: "setHideShippingBar", payload: shouldHide })
  }

  const showCart = () => {
    dispatch({ type: "showCart" })
  }

  const showFilter = () => {
    dispatch({ type: "showFilter" })
  }

  const showRegion = () => {
    dispatch({ type: "showRegion" })
  }

  const showProductNav = () => {
    dispatch({ type: "showProductNav" })
  }

  const toggleModal = () => {
    dispatch({ type: "toggleModal" })
  }

  const toggleRegionOpen = () => {
    dispatch({ type: "toggleRegionOpen" })
  }

  const toggleCart = () => {
    dispatch({ type: "toggleCart" })
  }

  const toggleFilter = () => {
    dispatch({ type: "toggleFilter" })
  }

  const toggleSearch = () => {
    dispatch({ type: "toggleSearch" })
  }

  const toggleProductNav = () => {
    dispatch({ type: "toggleProductNav" })
  }

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        setModalContent,
        setScrollable,
        hideDrawers,
        hideSearchDrawer,
        hideRegionDrawer,
        hideProductNav,
        setHeader,
        setRegion,
        setHideShippingBar,
        showCart,
        showFilter,
        showRegion,
        showProductNav,
        toggleModal,
        toggleRegionOpen,
        toggleCart,
        toggleFilter,
        toggleSearch,
        toggleProductNav,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}

export const useInterfaceContext = () => useContext(InterfaceContext)
