import React from "react"
import styled from "@emotion/styled"

export const VideoContainer = styled.iframe`
  height: 100%;
  width: 100%;
`

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : "1.5")};
`

const Video = ({ src, key, ...rest }) => {
  return (
    <Wrapper className="plyr__video-embed" id="player" key={key} {...rest}>
      <VideoContainer
        src={src}
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></VideoContainer>
    </Wrapper>
  )
}

export const YoutubeVideo = ({ src, key, ...rest }) => {
  return (
    <Wrapper
      key={key}
      id="player"
      data-plyr-provider="youtube"
      data-plyr-embed-id={src}
      {...rest}
    />
  )
}

export const VimeoVideo = ({ src, key, ...rest }) => {
  return (
    <Wrapper
      key={key}
      id="player"
      data-plyr-provider="vimeo"
      data-plyr-embed-id={src}
      {...rest}
    />
  )
}

export default Video
