import styled from "@emotion/styled"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, Flex } from "theme-ui"

import Arrow from "../../assets/icons/arrow.svg"
import useClickOutside from "../../hooks/useClickOutside"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"
import {
  bottomToTop,
  heightGrow,
  slideDown,
  slideUp,
} from "../../util/keyframes"
import { DesktopOnly, MobileOnly } from "../base"
import Text from "../base/text/text"

const SelectContainer = styled(Flex)`
  font-size: 12px;
  flex-direction: column;
  width: 100%;

  transition: all 400ms;

  .select-value {
    justify-content: space-between;
    align-items: center;
    border: ${(props) => props.theme.borders.thin};
    height: ${(props) => props.sx.height || "100%"};
    padding: 8px;
    font-size: 13px;
    color: ${(props) => props.theme.colors.darkGreen};

    .icon {
      ${(props) => props.open && "transform: rotate(180deg);"}
    }
  }

  .overlay {
    display: ${(props) => (props.open ? "block" : "none")};
    position: fixed;
    z-index: 9000;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .options {
    animation: ${bottomToTop} 200ms linear;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 60%;
    z-index: 100;
    overflow: scroll;
    font-size: 13px;
    display: ${(props) => (props.open ? "block" : "none")};
    border: 1px solid
      ${(props) =>
        props.variant === "darkGreen"
          ? props.theme.colors.darkGreen
          : props.theme.colors.opaqueGreen};
    background-color: ${(props) => props.theme.colors.background};

    .last-option {
      border-bottom: none;
    }
  }

  ${(props) => props.theme.bp.desktop} {
    position: relative;

    ${(props) =>
      props.calculatedHeight &&
      `
      height: ${props.calculatedHeight}px;
    `}

    .options {
      animation: ${(props) => (props.openUp ? slideUp : slideDown)} 200ms linear,
        ${heightGrow} 100ms linear;
      max-height: 300px;
      overflow: scroll;
      border-width: ${(props) =>
        props.openUp ? "1px 1px 0 1px" : "0 1px 1px 1px"};
      position: ${(props) => (props.openUp ? "absolute" : "relative")};
      bottom: ${(props) => (props.openUp ? "100%" : "0")};
      z-index: 100;
    }
  }
`

const Select = ({
  value,
  valueRender,
  valuePlaceholder,
  options,
  optionsRender,
  onOptionClick,
  allOptionsRender,
  showArrow = false,
  openUp = false,
  variant = "darkGreen",
  sx,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const valueRef = useRef()
  const selectRef = useRef()

  const [, setScrollable] = useDisableBackgroundScroll()

  const controlOpen = useCallback(() => {
    if (open) {
      setOpen(false)
    }
  }, [open])

  useClickOutside(selectRef, controlOpen, [open])

  useEffect(() => {
    if (!open) {
      setScrollable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleValueRender = () => {
    if (value) {
      if (valueRender) {
        return valueRender(value)
      }

      return <>{value.label}</>
    }

    return <span style={{ opacity: "0.3" }}>{valuePlaceholder}</span>
  }

  const handleContentRender = () => {
    if (allOptionsRender !== null && allOptionsRender !== undefined)
      return handleAllOptionsRender()
    if (optionsRender !== null && optionsRender !== undefined)
      return handleOptionsRender()

    return handleDefaultRender()
  }

  const handleAllOptionsRender = () => {
    return (
      <>
        <MobileOnly>
          <div className="overlay" onClick={() => setOpen(false)}>
            <div className="options">{allOptionsRender()}</div>
          </div>
        </MobileOnly>
        <DesktopOnly>
          <div className="options" onClick={() => setOpen(false)}>
            {allOptionsRender()}
          </div>
        </DesktopOnly>
      </>
    )
  }

  const handleOptionsRender = () => {
    return (
      <>
        <MobileOnly>
          <div className="overlay" onClick={() => setOpen(false)}>
            <div className="options">
              {options.map((option) => optionsRender(option))}
            </div>
          </div>
        </MobileOnly>
        <DesktopOnly>
          <div className="options" onClick={() => setOpen(false)}>
            {options.map((option) => optionsRender(option))}
          </div>
        </DesktopOnly>
      </>
    )
  }

  const handleDefaultRender = () => {
    return (
      <>
        <MobileOnly>
          <div className="overlay" onClick={() => setOpen(false)}>
            <div className="options">
              {options.map((option, i) => (
                <Option
                  key={option.value}
                  option={option}
                  onClick={() => {
                    setOpen(false)
                    onOptionClick(option)
                  }}
                  className={`option ${
                    options.length - 1 === i && "last-option"
                  }`}
                />
              ))}
            </div>
          </div>
        </MobileOnly>

        <DesktopOnly>
          <div className="options">
            {options.map((option, i) => (
              <Option
                key={option.value}
                option={option}
                onClick={() => {
                  setOpen(false)
                  onOptionClick(option)
                }}
                className={`option ${
                  options.length - 1 === i && "last-option"
                }`}
              />
            ))}
          </div>
        </DesktopOnly>
      </>
    )
  }

  return (
    <SelectContainer
      open={open}
      sx={{ flex: 1, ...sx }}
      ref={selectRef}
      openUp={openUp}
      variant={variant}
      {...rest}
    >
      <Box sx={{ height: "100%" }}>
        <Flex
          sx={{
            height: "100%",
            display: "flex",
            flex: "1",
            cursor: "pointer",
          }}
          className="select-value"
          ref={valueRef}
          onClick={() => {
            setOpen(!open)

            // if mobile, disable backgroundscroll
            if (window.innerWidth < 768) {
              setScrollable(false)
            }
          }}
        >
          <div>{handleValueRender()}</div>
          {showArrow && (
            <Flex
              sx={{
                width: "7px",
                height: "7px",
                transform: "rotate(90deg)",
                justifyItems: "center",
                alignItems: "center",
                color: "darkGreen",
              }}
            >
              <Arrow className="icon" />
            </Flex>
          )}
        </Flex>
      </Box>
      {handleContentRender()}
    </SelectContainer>
  )
}

const OptionContainer = styled(Flex)`
  padding: ${(props) => (props.sx?.padding ? props.sx.padding : "6px")};
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.opaqueGreen};

  .label {
    ${(props) =>
      props.hasAction &&
      `
    color: ${props.theme.colors.brownGrey};
    `}
    text-wrap: wrap;
  }

  .details {
    ${(props) => props.hasAction && "cursor: pointer;"}
  }

  .action {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.dark};
  }

  .text {
    strong {
      font-weight: bold;
      color: ${(props) => props.theme.colors.dark};
      margin-left: 5px;
    }
  }
`

const OptionWrapper = styled(Flex)`
  min-height: 32px;
  cursor: ${(props) =>
    props.disabled ? "default" : props.hasAction ? "default" : "pointer"};
  :hover {
    color: ${(props) => !props.disabled && props.theme.colors.background};
    background-color: ${(props) =>
      !props.disabled && props.theme.colors.lightGreen};
  }
`

export const Option = ({
  option,
  onClick,
  className,
  children,
  sx,
  ...rest
}) => {
  if (children) {
    return (
      <OptionWrapper>
        <OptionContainer
          onClick={handleOptionClicked}
          className={className}
          sx={{
            justifyContent: "space-between",
            ...sx,
          }}
          {...rest}
        >
          <div className="label">{children}</div>
        </OptionContainer>
      </OptionWrapper>
    )
  }
  const { label, details, action, disabled } = option
  const handleOptionClicked = () => {
    if (details.type === DetailTypes.ACTION) {
      return
    }
    onClick()
  }

  const handleActionClicked = () => {
    if (action && !disabled) {
      action()
    }
  }

  const handleDetails = () => {
    switch (details.type) {
      case DetailTypes.PRICE:
        return <div className="price">{details.value}</div>

      case DetailTypes.ACTION:
        return (
          <div className="action" onClick={handleActionClicked}>
            {details.text}
          </div>
        )
      case DetailTypes.TEXT:
        return (
          <div className="text">
            {details.value} <strong>{details.text}</strong>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <OptionWrapper disabled={disabled}>
      <OptionContainer
        onClick={handleOptionClicked}
        hasAction={details.type === DetailTypes.ACTION}
        className={className}
        sx={{
          justifyContent: "space-between",
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.5 : 1,
          ...sx,
        }}
        {...rest}
      >
        <div className="label">
          <Text>{label}</Text>
        </div>
        {handleDetails()}
      </OptionContainer>
    </OptionWrapper>
  )
}

export const DetailTypes = {
  PRICE: "price",
  ACTION: "action",
  TEXT: "text",
}

export default Select
